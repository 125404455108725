<template>
  <div class="container-fluid mt-5 pt-3" id="site-testimonials">
    
    <div class="row mt-2 mt-lg-5">
      <div class="col-12 testimonials">
        <vueper-slides
          ref="testimonials1"
          :gap="3"
          :slide-ratio="2/3"
          :bullets="false"
          @slide="$refs.testimonials2 && $refs.testimonials2.goToSlide($event.currentSlide.index, { emit: false })"
          class="no-shadow testimonials1"
          :breakpoints="breakpointsOne"
          :arrows="false"
          :dragging-distance="10"
          prevent-y-scroll
        >
          <vueper-slide v-for="(item, index) in testidata" :key="index">
            <template v-slot:content>
              <div class="testimonials__photo">
                <div class="w-100">
                  <img v-lazy="item.fimg_url" alt class="img-fluid" />
                </div>
              </div>
            </template>
          </vueper-slide>
        </vueper-slides>
        <div class="testimonials__copy w-100">
          <div class="nav-testimonials">
            <button class="arrow arrow--left" @click="$refs.testimonials1.previous()"><b-icon icon="arrow-left-short"></b-icon></button>
            <button class="arrow arrow--right" @click="$refs.testimonials1.next()"><b-icon icon="arrow-right-short"></b-icon></button>
          </div>
          <div class="block px-lg-5">
            <div class="block__content d-flex" :style="{ 'background-image':shapeTesti }">
              <div class="space"></div>
              <div class="copy p-2 p-lg-4">
                <h2 class="pt-2 pt-lg-5">Testimonial</h2>
                <vueper-slides
                  ref="testimonials2"
                  :bullets="false"
                  :slide-ratio="0.5"
                  class="testimonials2 no-shadow"
                  @slide="$refs.testimonials1 && $refs.testimonials1.goToSlide($event.currentSlide.index, { emit: false })"
                  :breakpoints="breakpointsTwo"
                  :arrows="false"
                  :dragging-distance="10"
                >
                  <vueper-slide v-for="(item, index) in testidata" :key="index">
                    <template v-slot:content>
                      <div v-html="item.content.rendered"></div>
                      <h4>{{item.title.rendered}}</h4>
                    </template>
                  </vueper-slide>
                </vueper-slides>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import shapeTestimony from "@/assets/img/shape-testimonials.svg";
import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";
export default {
  components: { VueperSlides, VueperSlide },
  props:{
      testidata: {},
  },
  data() {
    return {
      shapeTesti: "url(" + shapeTestimony + ")",
      breakpointsOne: {},
      breakpointsTwo: {
        1025: {
          slideRatio: 0.5
        },
        768: {
          slideRatio: 0.8
        }
      }
    };
  }
};
</script>

<style>
#site-testimonials {
  overflow-x: hidden;
  position: relative;
}
</style>