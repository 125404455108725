<template>
  <div class="row justify-content-center mb-5 pb-5">
        <div class="col-12 offset-lg-1 col-lg-6 text-center">
          <h2>Team</h2>
        </div>
        <div class="col-12 offset-lg-1 col-lg-10">
          <div class="row team justify-content-center">
            <team-member v-for="(member, index) in teamdata" :key="index" :name="member.name" :position='member.position' :bio='member.bio' :index="index" :imgUrl="member.photo"></team-member>
          </div>
        </div>
      </div>
</template>

<script>
import TeamMember from "@/components/about/teamMember"
export default {
  components:{
    TeamMember
  },
  props:{
    teamdata:Array
  },
  data(){
    return{
    }
  }
}
</script>
