<template>
<layout-default>
  <section v-if="dataErrored">
      <p>We're sorry, we're not able to retrieve this information at the moment, please try back later</p>
    </section>
  <div v-else class="layout__about">
    <div class="container-fluid position-relative overflow-hidden">
      <div class="row">
        <div class="col-12 offset-lg-1 col-lg-5 offset-xl-1 col-xl-4 mt-10">
          <h1 v-if="itLoads">{{pageData.title.rendered}}</h1>
          <div class="w-100 py-5" v-if="itLoads" v-html="pageData.content.rendered">
          </div>
        </div>
      </div>

      <div class="container--shapes d-none d-lg-block" v-if="itLoads">
        <div id="R" class="letter">
          <img v-lazy="pageData.acf.r_img" class="letter__img"/>
        </div>

        <div id="B" class="letter">
          <img :src="pageData.acf.b_img" class="letter__img"/>
        </div>

        <div id="D" class="letter" >
            <img v-lazy="pageData.acf.d_img" class="letter__img"/>
        </div>
      </div>
      <div data-aos="fade-up" class="container-fluid d-lg-none pb-5" v-if="itLoads">
        <div class="row">
          <div class="col-12">
            <img v-lazy="pageData.fimg_url" class="img-fluid"/>
          </div>
        </div>
      </div>
      <div class="black-space d-none d-lg-block mb-lg-5"></div>
      <about-team v-if="itLoads" data-aos="fade-up" :teamdata="pageData.acf.team"></about-team>
    </div>

    <Testimonials v-if="itLoads" :testidata="testiomonialsData" />
  </div>
  </layout-default>
</template>

<script>
import AboutTeam from "@/components/about/aboutTeam";
import Testimonials from "@/components/about/Testimonials.vue";
import LayoutDefault from '@/layouts/LayoutDefault';

export default {
  inject:['MyLoading'],
  components: { Testimonials, AboutTeam, LayoutDefault },
  data() {
    return {
      pageData: {
        title:{
          rendered: 'About'
        },
        content:{
          rendered: 'About'
        },
        acf:{
          team:{}
        }
      },
      testiomonialsData: {},
      itLoads: false,
       dataLoading: true,
      dataErrored: false,
    };
  },
  head() {
    if(this.dataLoading == false) {
      const metaArray = [];
      this.pageData.yoast_meta.map(ele => {
        metaArray.push({
          hid: ele.name ? ele.name : ele.property,
          name: ele.name ? ele.name : ele.property,
          content: ele.content,
        });
      });

      return {
        title: this.dataLoading ? 'RBD' : this.pageData._yoast_wpseo_title,
        meta: metaArray,
      }
    }
  },
  methods: {
    fetchingData() {
      this.$axios
        .get(this.$axios.defaults.baseURL + "wp/v2/pages/11")
        .then(response => {
          this.pageData = response.data;
          this.itLoads = true;
           this.MyLoading.val = false;
        })
        .catch((error) => {
          console.log(error);
          this.dataErrored = true
        }).finally(() => this.dataLoading = false);
    },
    fetchingTestimonials() {
      this.$axios
        .get(this.$axios.defaults.baseURL + "wp/v2/testimonial")
        .then(response => {
          this.testiomonialsData = response.data;
        })
        .catch(error => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.fetchingData();
    this.fetchingTestimonials();
  },
  created() {
    this.MyLoading.val = true;
  }
};
</script>
