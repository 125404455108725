<template>
  <div class="col-12 col-sm-7 col-md-6 col-lg-4 member py-4 py-lg-2">
    <div class="member__picture">
      <img :src="imgUrl" :alt="name"  />
    </div>
    <div class="member__desc d-flex">
      <div class="indicator">
        <span class="number tx-bg tx-calibre text-white">0{{index+1}}</span>
        <span class="angle"></span>
      </div>
      <div class="info pl-3 pt-4">
        <h3 class="mb-0">{{name}}</h3>
        <span class="line mb-2"></span>
        <p class="font-weight-bold" v-html="position"></p>
        <div class="w-100" v-html="bio"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props:{
    index: Number,
    name: String,
    position: String,
    bio: String,
    imgUrl: String
  },
  data() {
    return {
    };
  }
};
</script>